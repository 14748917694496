.aside {
	padding: 2rem;
	/* max-width: 38vw; */
}

.aside .asideWrapper {
}

.aside .asideWrapper h1 {
	font-size: 1.8rem;
	font-weight: 500;
	margin-bottom: 1.5rem;
}

.service {
	background: #ffefd75b;
	padding: 1rem;
	margin-top: 2rem;
	border-radius: 5px;
}

.service h3 {
	font-weight: 500;
}

.container {
	margin: 1rem 0;
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 2px rgba(34, 36, 38, 0.25);
	border-radius: 5px;
	padding: 1rem;
	background: white;
}

.helperText {
	font-size: 0.8rem;
	margin-top: 1rem;
	color: #056905;
}

.cardHeader {
	display: flex;
	align-items: center;
	width: 100%;
}

.headerLeft {
	display: flex;
	align-items: center;
	flex: 1;
}

.headerLeft p {
	margin-left: 0.5rem;
}

.textLight {
	font-weight: 300;
}

.textBold {
	font-weight: 500;
}
