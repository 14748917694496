.main {
	padding: 1rem;
	width: 100%;
}

.header {
	width: 100%;
	display: flex;
	align-items: center;
}

.head {
	flex: 1;
	padding-left: 1.5rem;
}

.head h3 {
	font-size: 1.2rem;
}

.datePicker {
	margin-top: 0.8rem;
	padding: 1rem;
	outline: 1px solid #cecece;
	width: 100%;
	max-width: 500px;
	/* background-color: #d7e6ff5b; */
}

.slots {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 0.5rem;
	margin-top: 0.5rem;
}

.box {
	padding: 1rem;
}

.box h3 {
	font-size: 1rem;
	font-weight: 500;
	color: #373737;
}

@media only screen and (max-width: 991px) {
	.slots {
		grid-template-columns: repeat(2, 1fr);
	}
}
