.main {
	padding: 1rem;
	width: 100%;
}

.header {
	width: 100%;
	display: flex;
	align-items: center;
}

.head {
	flex: 1;
	padding-left: 1.5rem;
}

.head h3 {
	font-size: 1.2rem;
}

.box {
	width: 100%;
	padding: 1rem;
	background-color: #ffefd75b;
	border-radius: 0.5rem;
}

.innerBox {
	width: 100%;
	background-color: white;
	padding: 1rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 0.8rem;
}

.verifyBox {
	padding: 1rem;
	width: 100%;
}

.input {
	padding: 1rem;
	margin-top: 1rem;
}
