@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	position: relative;
}

::-webkit-scrollbar {
	display: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

:root {
	--theme-color: #fd914d;
}

.MuiAvatar-root {
	width: 10rem !important;
	height: 10rem !important;
}

@media only screen and (max-width: 991px) {
	.MuiAvatar-root {
		width: 6rem !important;
		height: 6rem !important;
	}
}
