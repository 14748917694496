.navbar {
	background-color: var(--theme-color);
	box-shadow: 0 0 5px var(--theme-color);
	width: 100%;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 999;
}

.navContainer {
	padding: 0.5rem 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.navLogo span {
	font-weight: 800;
	font-size: 1.8rem;
	color: white;
}

.navButton {
	background: var(--theme-color) !important;
	border: 2px solid white !important;
	border-radius: 0.6rem !important;
	padding: 0.5rem 1rem !important;
	color: white !important;
}

.navButton:hover {
	background: white;
}
