.body {
	overflow: hidden;
	width: 100%;
	display: flex;
	position: relative;
}

.profile {
	overflow-y: auto;
	flex: 0.62;
}

.aside {
	flex: 0.38;
}

@media only screen and (max-width: 991px) {
	.body {
		display: block;
	}
}
