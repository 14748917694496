.mainSection {
	padding: 2rem;
	padding-left: 5rem;
	background-color: #ffefd75b;
}

.header {
	display: flex;
	margin-bottom: 2.5rem;
}

.logo {
	padding-right: 2.5rem;
}

.profile {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 1rem;
}

.profile .regNo {
	font-size: 0.8rem;
	font-weight: 300;
}

.profile .name {
	font-size: 2rem;
	font-weight: 500;
}

.profile .speciality {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	color: #585858;
}

/* .profile .smallHeading {
	font-weight: 500;
	font-size: 1rem;
	margin-bottom: 0.5rem;
} */

/* .profile .lightText {
	font-weight: 300;
} */

.container {
	margin: 1rem 0;
	display: flex;
	box-shadow: 0 0 2px rgba(34, 36, 38, 0.25);
	border-radius: 5px;
	padding: 1rem;
	background: white;
}

.container .exp,
.container .lang {
	display: flex;
	flex: 1;
}

.container .smallHeading {
	font-weight: 500;
	font-size: 1rem;
	margin-bottom: 0.5rem;
}

.container .lightText {
	font-weight: 300;
}

.container .lightColor {
	color: #585858;
}

.list {
	display: flex;
	align-items: center;
}

.fact {
	margin: 1rem 0;
}

@media only screen and (max-width: 991px) {
	.mainSection {
		padding-left: 2rem;
	}

	.profile .name {
		font-size: 1.5rem;
	}
}
